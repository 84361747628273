body {
    margin: 0;
}

.background-video {
    opacity: 0.6;

    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    transform: translateX(-50%) translateY(-50%);
    transition: 1s opacity;
}

.social-icon {
    height: 32px;
}
